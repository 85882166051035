import BaseService from './base.service';
import Environments from '../../common/EnvVars';

class ProductService extends BaseService {
  path = () => 'product';

  create(values) {
    const _p = this.path();
    const axiosClient = this.getAxiosClient();
    axiosClient.defaults.headers['Content-Type'] = 'multipart/form-data';
    return axiosClient
      .post(`${_p}/create`, values)
      .then((response) => {
        axiosClient.defaults.headers['Content-Type'] = 'application/json';
        return response;
      })
      .catch((error) => {
        throw this.getDataError(error);
      });
  }

  getImageFull = (url) => {
    let newUrl = url ? url : Environments.IMAGE_DEFAULT.replaceAll('%s', this.path);
    return `${Environments.HOST_URL}/${newUrl}`;
  };

}

export default new ProductService();
