import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

console.log('REACT_APP_API_URL web-app', REACT_APP_API_URL);

interface ApiResponse {
  data?: any;
  error: boolean;
  message?: string;
  field?: string;
}

const axiosClient = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json'
  }
});
axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      const { error, message, field } = response.data as ApiResponse;
      if (error && field === 'wrong_authentication_token') {
        localStorage.removeItem('user');
        window.location.reload();
      }
      return response.data;
    }

    return response;
  },
  (error) => {
    console.log(error);
    throw error;
  }
);

export default axiosClient;
